// PopupForm.js
import React from 'react';

const PopupForm = ({ children, onClose, title }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{title}</h2>
          <button onClick={onClose} className="text-xl">&times;</button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PopupForm;