import React from 'react';
import { GoogleLogin } from '@react-oauth/google';

const Login = ({ onLoginSuccess }) => {

  const handleLoginSuccess = (credentialResponse) => {
    // console.log('Login Success:', credentialResponse);
    onLoginSuccess(credentialResponse.credential);
  };

  const handleLoginError = () => {
    console.log('Login Failed');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-3xl font-bold text-center mb-6">Please log in</h2>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginError}
          useOneTap
          className="w-full"
        />
      </div>
    </div>
  );
};

export default Login;
