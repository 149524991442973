import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';

const LogoutButton = () => {
  const { logout } = useContext(AuthContext);

  return (
    <button onClick={logout} className="bg-red-500 text-white px-4 py-2 rounded">
      Log Out
    </button>
  );
};

export default LogoutButton;
