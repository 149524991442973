import React, { useState ,useContext } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';

const AddVideoForm = ({ onVideoAdded, selectedPlaylistId, playlists }) => {
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { userToken } = useContext(AuthContext);


  const handleUrlChange = (e) => {
    setYoutubeUrl(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!selectedPlaylistId) {
      setError('Please select a playlist');
      setLoading(false);
      return;
    }

    const api = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

    try {
      const response = await api.post('v1/videos/add-from-url', {
        youtube_url: youtubeUrl,
        playlist_id: selectedPlaylistId
      });
      onVideoAdded(response.data);
      setYoutubeUrl('');
    } catch (error) {
      setError('Failed to add video. Please check the URL and try again.');
      console.error('Error adding video:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto mt-8">
      <div className="mb-4">
        <label htmlFor="youtube_url" className="block mb-2">YouTube Video URL:</label>
        <input
          type="text"
          id="youtube_url"
          value={youtubeUrl}
          onChange={handleUrlChange}
          required
          className="w-full px-3 py-2 border rounded"
          placeholder="https://www.youtube.com/watch?v=..."
        />
      </div>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <button 
        type="submit" 
        className="bg-blue-500 text-white px-4 py-2 rounded w-full"
        disabled={loading || !selectedPlaylistId}
      >
        {loading ? 'Adding...' : 'Add Video'}
      </button>
    </form>
  );
};

export default AddVideoForm;