import VideoTable from './components/VideoTable';
import React, { useContext } from 'react';
import { AuthContext } from './components/AuthContext';
import Login from './components/Login';
import LogoutButton from './components/LogoutButton';

function App() {
  const { userToken, setUserToken } = useContext(AuthContext);

  const handleLoginSuccess = (token) => {
    setUserToken(token);
  };

  if (!userToken) {
    return <Login onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <div className="App">
      <header className="bg-blue-500 text-white p-4 mb-4">
        <h1 className="text-2xl">Video Playlist App</h1>
        <LogoutButton />
      </header>
      <VideoTable />
    </div>
  );
}

export default App;