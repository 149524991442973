import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(localStorage.getItem('userToken'));

  useEffect(() => {
    if (userToken) {
      localStorage.setItem('userToken', userToken);
    } else {
      localStorage.removeItem('userToken');
    }
  }, [userToken]);

  const logout = () => {
    setUserToken(null);
    localStorage.removeItem('userToken');
  };

  return (
    <AuthContext.Provider value={{ userToken, setUserToken, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
