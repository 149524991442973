// CreatePlaylistForm.js
import React, { useState ,useContext } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';

const CreatePlaylistForm = ({ onPlaylistCreated }) => {
  const [title, setTitle] = useState('');
  const [error, setError] = useState('');
  const { userToken } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    //needs to make it as util and use in all places
    const api = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    try {
      const response = await api.post('v1/playlists', { title });
      onPlaylistCreated(response.data);
      setTitle('');
    } catch (error) {
      setError('Failed to create playlist. Please try again.');
      console.error('Error creating playlist:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-4">
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="New Playlist Title"
        className="border p-2 mr-2"
        required
      />
      <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
        Create Playlist
      </button>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </form>
  );
};

export default CreatePlaylistForm;