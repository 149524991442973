import React, { useState, useEffect, useCallback, useRef, useContext  } from 'react';
import axios from 'axios';
import AddVideoForm from './AddVideoForm';
import PopupForm from './PopupForm';
import CreatePlaylistForm from './CreatePlaylistForm';
import { AuthContext } from './AuthContext';


const VIDEO_PER_PAGE = 100;


const VideoTable = () => {
  const [videos, setVideos] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylist] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: 'duration', direction: 'ascending' });
  const [totalVideos, setTotalVideos] = useState(0);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingNotes, setEditingNotes] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [deletingVideo, setDeletingVideo] = useState(null);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState('');
  const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);
  const [showCreatePlaylistForm, setShowCreatePlaylistForm] = useState(false);
  const [deletingPlaylistId, setDeletingPlaylistId] = useState(null);
  const { userToken } = useContext(AuthContext);

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const incrementViewCount = async (videoId) => {
    try {
      const response = await api.post(`v1/videos/${videoId}/update-view-count`, {
        increment: 1
      });
      setVideos(prevVideos => prevVideos.map(video => 
        video.video_id === videoId ? { ...video, view_count: response.data.view_count } : video
      ));
    } catch (error) {
      console.error('Error incrementing view count:', error);
      setError('Failed to update view count. Please try again.');
    }
  };

  const handleThumbnailClick = (videoId, youtubeId) => {
    incrementViewCount(videoId);
    openYouTubeVideo(youtubeId);
  };


  const deletePlaylist = async (playlistId) => {
    try {
      await api.delete(`v1/playlists/${playlistId}`);
      setPlaylists(playlists.filter(playlist => playlist.playlist_id !== playlistId));
      if (selectedPlaylistId === playlistId) {
        setSelectedPlaylistId('');
        setVideos([]);
      }
      setDeletingPlaylistId(null);
    } catch (error) {
      console.error('Error deleting playlist:', error);
      setError('Failed to delete playlist. Please try again.');
    }
  };


  const handlePlaylistCreated = (newPlaylist) => {
    setPlaylists([...playlists, newPlaylist]);
    setShowCreatePlaylistForm(false);
  };

  const toggleFavorite = async (videoId) => {
    try {
      const response = await api.patch(`v1/videos/${videoId}/toggle-favorite`);
      setVideos(prevVideos => prevVideos.map(video => 
        video.video_id === videoId ? { ...video, is_favorite: response.data.is_favorite } : video
      ));
    } catch (error) {
      console.error('Error toggling favorite status:', error);
      setError('Failed to update favorite status. Please try again.');
    }
  };
 
  const deleteVideo = async (videoId) => {
    try {
      await api.delete(`v1/videos/${videoId}`);
      setVideos(prevVideos => prevVideos.filter(video => video.video_id !== videoId));
      setDeletingVideo(null);
    } catch (err) {
      setError('Failed to delete video. Please try again.');
    }
  };

  const sortedVideos = React.useMemo(() => {
    let sortableVideos = [...videos];
    if (sortConfig.key !== null) {
      sortableVideos.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableVideos;
  }, [videos, sortConfig]);
  
  const filteredVideos = React.useMemo(() => {
    return sortedVideos
      .filter(video =>
        video.title.toLowerCase().includes(nameFilter.toLowerCase()) &&
        (!showOnlyFavorites || video.is_favorite)
      );
  }, [sortedVideos, nameFilter, showOnlyFavorites]);
  const handleVideoAdded = (newVideo) => {
    setVideos(prevVideos => [newVideo, ...prevVideos]);
    setShowAddForm(false);
  };

  const handlePlaylistChange = (e) => {
    const playlistId = e.target.value;
    setSelectedPlaylistId(playlistId);
    setVideos([]);
    setPage(1);
    setHasMore(true);
    setTotalVideos(0);
  };


  const updateNotes = async (videoId, newNotes) => {
    try {
      const response = await api.patch(`v1/videos/${videoId}/notes`, { notes: newNotes });
      setVideos(prevVideos =>
        prevVideos.map(video =>
          video.video_id === videoId ? { ...video, notes: response.data.notes } : video
        )
      );
      setEditingNotes(null);
    } catch (err) {
      setError('Failed to update notes. Please try again.');
    }
  };



  const observer = useRef();
  const lastVideoElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);


  function fetchPlaylists() {
    setLoading(true);
    setError(null);
    return api.get('v1/playlists')
      .then(response => setPlaylists(response.data))
      .catch(err => setError('Failed to fetch playlists. Please try again.'))
      .finally(() => setLoading(false));
  }
  
  useEffect(() => {
    fetchPlaylists();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVideos = useCallback(async () => {
    if (!selectedPlaylistId || !hasMore) return;
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`v1/playlists/${selectedPlaylistId}/videos`, {
        params: { page, size: VIDEO_PER_PAGE }
      });
      if (response.data.items.length === 0) {
        setHasMore(false);
      } else {
        setVideos(prevVideos => [...prevVideos, ...response.data.items]);
        setTotalVideos(response.data.total);
        setHasMore(response.data.total > page * VIDEO_PER_PAGE);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setHasMore(false);
      } else {
        setError('Failed to fetch videos. Please try again.');
      }
    }
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlaylistId, page]);

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  useEffect(() => {
    if (selectedPlaylist) {
      setVideos([]);
      setPage(1);
      setHasMore(true);
      setTotalVideos(0);
    }
  }, [selectedPlaylist]);


  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const SortButton = ({ columnName }) => (
    <button onClick={() => requestSort(columnName)} className="ml-2">
      {sortConfig.key === columnName ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : '↕'}
    </button>
  );

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const openYouTubeVideo = (youtubeId) => {
    window.open(`https://www.youtube.com/watch?v=${youtubeId}`, '_blank');
  };

  const updateViewCount = async (videoId, increment) => {
    try {
      const response = await api.post(`v1/videos/${videoId}/update-view-count`, { increment });
      setVideos(prevVideos =>
        prevVideos.map(video =>
          video.video_id === videoId
            ? { ...video, view_count: response.data.view_count }
            : video
        )
      );
    } catch (err) {
      setError('Failed to update view count. Please try again.');
    }
  };

  
  return (
    <div className="container mx-auto p-4">
      <div className="mb-4">
        <select
          value={selectedPlaylistId}
          onChange={handlePlaylistChange}
          className="border p-2 mr-2"
        >
          <option value="">Select playlist</option>
          {playlists.map((playlist) => (
            <option key={playlist.playlist_id} value={playlist.playlist_id}>
              {playlist.title}
            </option>
          ))}
        </select>
        <button 
          onClick={() => setShowAddForm(true)} 
          className="bg-green-500 text-white px-4 py-2 rounded mr-2"
        >
          Add New Video
        </button>
        <button 
          onClick={() => setShowCreatePlaylistForm(true)} 
          className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
        >
          Create New Playlist
        </button>
        {selectedPlaylistId && (
          <button 
            onClick={() => setDeletingPlaylistId(selectedPlaylistId)} 
            className="bg-red-500 text-white px-4 py-2 rounded mr-2"
          >
            Delete Playlist
          </button>
        )}
        <input
          type="text"
          placeholder="Filter by name"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
          className="border p-2"
        />
      </div>

      <div className="mb-4">
  {/* ... (existing filters and buttons) */}
  <label className="inline-flex items-center ml-4">
    <input
      type="checkbox"
      checked={showOnlyFavorites}
      onChange={(e) => setShowOnlyFavorites(e.target.checked)}
      className="form-checkbox h-5 w-5 text-blue-600"
    />
    <span className="ml-2 text-gray-700">Show only favorites</span>
  </label>
</div>

      {showAddForm && (
        <PopupForm onClose={() => setShowAddForm(false)} title="Add New Video">
          <AddVideoForm 
            onVideoAdded={handleVideoAdded} 
            selectedPlaylistId={selectedPlaylistId} 
            playlists={playlists}
          />
        </PopupForm>
      )}

{showCreatePlaylistForm && (
        <PopupForm onClose={() => setShowCreatePlaylistForm(false)} title="Create New Playlist">
          <CreatePlaylistForm onPlaylistCreated={handlePlaylistCreated} />
        </PopupForm>
      )}

{deletingPlaylistId && (
        <PopupForm onClose={() => setDeletingPlaylistId(null)} title="Confirm Deletion">
          <p>Are you sure you want to delete this playlist? This action cannot be undone.</p>
          <div className="mt-4 flex justify-end">
            <button
              onClick={() => setDeletingPlaylistId(null)}
              className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
            >
              Cancel
            </button>
            <button
              onClick={() => deletePlaylist(deletingPlaylistId)}
              className="bg-red-500 text-white px-4 py-2 rounded"
            >
              Delete
            </button>
          </div>
        </PopupForm>
      )}


      {filteredVideos.length > 0 ? (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-4 py-2">Thumbnail</th>
              <th className="px-4 py-2">
                Title
                <SortButton columnName="title" />
              </th>
              <th className="px-4 py-2">
                Duration
                <SortButton columnName="duration" />
              </th>
              <th className="px-4 py-2">
                Is Favorite
                <SortButton columnName="is_favorite" />
              </th>
              <th className="px-4 py-2">
                View Count
                <SortButton columnName="view_count" />
              </th>
              <th className="px-4 py-2">Notes</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredVideos.map((video, index) => (
              <tr key={video.video_id} ref={index === filteredVideos.length - 1 ? lastVideoElementRef : null}>
                <td className="border px-4 py-2">
                  {
                  //Need to refactor this part
                  }
                  <img
                    src={video.thumbnails.high.url || video.thumbnails.medium.M.url.S}
                    alt={video.title}
                    width={320}
                    height={480}
                    className="object-cover cursor-pointer"
                    onClick={() => handleThumbnailClick(video.video_id, video.youtube_id)}
                  />
                </td>
                <td className="border px-4 py-2">{video.title}</td>
                <td className="border px-4 py-2">{formatDuration(video.duration)}</td>
                <td className="border px-4 py-2">
              <button
                onClick={() => toggleFavorite(video.video_id)}
                className={`px-2 py-1 rounded ${video.is_favorite ? 'bg-yellow-500 text-white' : 'bg-gray-200 text-gray-700'}`}
              >
                {video.is_favorite ? '★ Favorite' : '☆ Add to Favorites'}
              </button>
            </td>
                <td className="border px-4 py-2">{video.view_count}</td>
                <td className="border px-4 py-2">
                  {editingNotes === video.video_id ? (
                    <input
                      type="text"
                      value={video.notes || ''}
                      onChange={(e) => {
                        setVideos(prevVideos =>
                          prevVideos.map(v =>
                            v.video_id === video.video_id ? { ...v, notes: e.target.value } : v
                          )
                        );
                      }}
                      onBlur={() => updateNotes(video.video_id, video.notes)}
                      autoFocus
                    />
                  ) : (
                    <span onClick={() => setEditingNotes(video.video_id)}>
                      {video.notes || 'Click to add notes'}
                    </span>
                  )}
                </td>
                <td className="border px-4 py-2">
                  <button
                    onClick={() => updateViewCount(video.video_id, 1)}
                    className="bg-green-500 text-white px-2 py-1 rounded mr-2"
                  >
                    +
                  </button>
                  <button
                    onClick={() => updateViewCount(video.video_id, -1)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    -
                  </button>
                  <button
                    onClick={() => setDeletingVideo(video.video_id)}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>

           {/* Add confirmation modal */}
  {deletingVideo && (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="bg-white p-5 rounded-lg">
        <h2 className="text-xl mb-4">Are you sure you want to delete this video?</h2>
        <div className="flex justify-end">
          <button
            onClick={() => setDeletingVideo(null)}
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={() => deleteVideo(deletingVideo)}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )}

        </table>
      ) : (
        <p>No videos to display. Please select a playlist or adjust your filter.</p>
      )}
      {loading && <p className="mt-4">Loading more videos...</p>}
      {!hasMore && videos.length > 0 && <p className="mt-4">No more videos to load.</p>}
    </div>
  );
};

export default VideoTable;